import {
  FETCH_CREDIT_CARD,
  FETCH_CREDIT_CARD_SUCCESS,
  FETCH_CREDIT_CARD_FAILED,
  ADD_CREDIT_CARD,
  ADD_CREDIT_CARD_SUCCESS,
  ADD_CREDIT_CARD_FAILED,
  AUTHORIZE_CREDIT_CARD,
  AUTHORIZE_CREDIT_CARD_SUCCESS,
  AUTHORIZE_CREDIT_CARD_FAILED,
  CAPTURE_CREDIT_CARD,
  CAPTURE_CREDIT_CARD_SUCCESS,
  CAPTURE_CREDIT_CARD_FAILED,
  VOID_CREDIT_CARD,
  VOID_CREDIT_CARD_SUCCESS,
  VOID_CREDIT_CARD_FAILED,
} from "../store/types";
import { firebase } from "../config/configureFirebase";
import store from "../store/store";
import { onValue, push, get, update, off } from "firebase/database";
import base64 from "react-native-base64";
import AccessKey from "../other/AccessKey";
import { formatDisplayCardNumber } from "../../../mobile-app/src/utils/formatters";

export const fetchCreditCard = () => (dispatch) => {
  const { creditCardRef } = firebase;

  dispatch({
    type: FETCH_CREDIT_CARD,
    payload: null,
  });

  const userInfo = store.getState().auth.profile;

  onValue(creditCardRef(userInfo.uid), (snapshot) => {
    const data = snapshot.val();
    console.log(data);
    if (data) {
      const arr = Object.keys(data).map((i) => {
        data[i].id = i;
        return data[i];
      });
      console.log(arr);
      dispatch({
        type: FETCH_CREDIT_CARD_SUCCESS,
        payload: arr,
      });
    } else {
      dispatch({
        type: FETCH_CREDIT_CARD_FAILED,
        payload: "No cards found",
      });
    }
  });
};

export const addCreditCard = (data) => async (dispatch) => {
  const { config, creditCardRef } = firebase;

  const userInfo = store.getState().auth.profile;

  dispatch({
    type: ADD_CREDIT_CARD,
    payload: null,
  });

  let cardDetail = {
    cardNumber: data.number,
    expirationDate: data.expiryDate,
    cvv: data.cvv,
    email: data.email,
    firstName: data.firstName,
    lastName: data.lastName,
    address: data.address,
    city: data.city,
    state: data.state,
    zip: data.zip,
    country: data.country,
  };

  const settings = store.getState().settingsdata.settings;
  let host =
    window &&
    window.location &&
    settings.CompanyWebsite === window.location.origin
      ? window.location.origin
      : `https://${config.projectId}.web.app`;
  let url = `${host}/tokenizeCard`;
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Basic " + base64.encode(config.projectId + ":" + AccessKey),
    },
    body: JSON.stringify(cardDetail),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      if (responseJson.success === true) {
        let details = {
          user: userInfo.uid,
          date: new Date().getTime(),
          number: formatDisplayCardNumber(data.number),
          firstName: data.firstName,
          lastName: data.lastName,
          expiryDate: data.expiryDate,
          type: data.type,
          email: data.email,
          customerProfileId: responseJson.customerProfileId,
          paymentProfileId: responseJson.paymentProfileId,
        };

        console.log(details);

        onValue(
          push(creditCardRef(userInfo.uid), details)
            .then(() => {
              dispatch({
                type: ADD_CREDIT_CARD_SUCCESS,
                payload: null,
              });
            })
            .catch((error) => {
              dispatch({
                type: ADD_CREDIT_CARD_FAILED,
                payload: error.code + ": " + error.message,
              });
            }),
          { onlyOnce: true }
        );
      } else {
        dispatch({
          type: ADD_CREDIT_CARD_FAILED,
          payload: "001" + ": " + "Can't add card",
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: ADD_CREDIT_CARD_FAILED,
        payload: error,
      });
    });
};

export const authorizePayment = (data) => async (dispatch) => {
  const {
    config,
    bookingListRef,
    singleUserRef,
    walletBalanceRef,
    settingsRef,
    singleBookingRef,
  } = firebase;

  dispatch({
    type: AUTHORIZE_CREDIT_CARD,
    authorize: null,
  });

  const userInfo = store.getState().auth.profile;

  const authorizeDetail = {
    amount: data.amount,
    paymentProfileId: data.paymentProfileId,
    customerProfileId: data.customerProfileId,
  };

  let host = `https://${config.projectId}.web.app`;
  let url = `${host}/authorizePayment`;
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Basic " + base64.encode(config.projectId + ":" + AccessKey),
    },
    body: JSON.stringify(authorizeDetail),
  })
    .then((response) => response.json())
    .then(async (responseJson) => {
      console.log("Entró 00");
      if (responseJson.success === true) {
        console.log("Entró 01");
        const booking = await get(
          bookingListRef(userInfo.uid, userInfo.usertype)
        );
        const bookingData = booking.val();

        //UpdateBooking
        const settingsdata = await get(settingsRef);
        const settings = settingsdata.val();
        console.log("Entró 02");
        let curChanges = {
          status:
            bookingData.booking_from_web && !settings.prepaid
              ? "COMPLETE"
              : settings.prepaid
              ? "NEW"
              : "PAID",
          prepaid: settings.prepaid,
          transaction_id: responseJson.transactionId,
          gateway: "Authorize.net",
        };
        console.log(curChanges);
        Object.assign(curChanges, bookingData.paymentPacket);
        update(singleBookingRef(data.order_id), curChanges);
        update(singleUserRef(bookingData.driver), { queue: false });
        //End UpdateBooking

        dispatch({
          type: AUTHORIZE_CREDIT_CARD_SUCCESS,
          payload: responseJson,
        });

        dispatch({
          type: AUTHORIZE_CREDIT_CARD_SUCCESS,
          payload: responseJson,
        });
      } else {
        dispatch({
          type: AUTHORIZE_CREDIT_CARD_FAILED,
          payload: responseJson.message,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: AUTHORIZE_CREDIT_CARD_FAILED,
        payload: error,
      });
    });
};

export const capturePayment = (data) => async (dispatch) => {
  const { config } = firebase;

  dispatch({
    type: CAPTURE_CREDIT_CARD,
    capture: null,
    payload: null,
  });

  const captureDetail = {
    transactionId: data.transactionId,
  };

  let host = `https://${config.projectId}.web.app`;
  let url = `${host}/capturePayment`;
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Basic " + base64.encode(config.projectId + ":" + AccessKey),
    },
    body: JSON.stringify(captureDetail),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      if (responseJson.success === true) {
        dispatch({
          type: CAPTURE_CREDIT_CARD_SUCCESS,
          payload: responseJson,
        });
      } else {
        dispatch({
          type: CAPTURE_CREDIT_CARD_FAILED,
          payload: responseJson.message,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: CAPTURE_CREDIT_CARD_FAILED,
        payload: error,
      });
    });
};

export const voidPayment = (data) => async (dispatch) => {
  const { config } = firebase;

  dispatch({
    type: VOID_CREDIT_CARD,
    cancel: null,
    payload: null,
  });

  const voidDetail = {
    transactionId: data.transactionId,
  };

  const settings = store.getState().settingsdata.settings;
  let host =
    window &&
    window.location &&
    settings.CompanyWebsite === window.location.origin
      ? window.location.origin
      : `https://${config.projectId}.web.app`;
  let url = `${host}/voidTransaction`;
  fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Basic " + base64.encode(config.projectId + ":" + AccessKey),
    },
    body: JSON.stringify(voidDetail),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      console.log(responseJson);
      if (responseJson.success === true) {
        dispatch({
          type: VOID_CREDIT_CARD_SUCCESS,
          payload: responseJson,
        });
      } else {
        dispatch({
          type: VOID_CREDIT_CARD_FAILED,
          payload: responseJson.message,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: VOID_CREDIT_CARD_FAILED,
        payload: error,
      });
    });
};
